import womanTouch from "../../assets/img/social-web-network-mixed-media.webp";
import Footer from "../../components/Footer";

import banner from "../../assets/img/about-banner.webp";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import ServiceOffter from "../../components/ServiceOffer";

import dustin from "../../assets/img/partners/dustion.png";
import hqSoft from "../../assets/img/partners/hqsoft.png";
// import tefal from "../../assets/img/partners/tefal.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import client1 from "../../assets/img/client/1.png";
import client2 from "../../assets/img/client/2.png";
import client3 from "../../assets/img/client/3.png";
import client4 from "../../assets/img/client/4.png";
import client5 from "../../assets/img/client/5.png";
import client6 from "../../assets/img/client/6.png";
import client7 from "../../assets/img/client/7.png";
import client8 from "../../assets/img/client/8.png";
import client9 from "../../assets/img/client/9.png";
import client10 from "../../assets/img/client/10.png";
import client11 from "../../assets/img/client/11.png";
import client12 from "../../assets/img/client/12.png";
import client13 from "../../assets/img/client/13.png";
import client14 from "../../assets/img/client/14.png";
import client15 from "../../assets/img/client/15.png";
import client16 from "../../assets/img/client/16.png";
import client17 from "../../assets/img/client/17.png";
import "./styles.css";
import TeammateBox from "components/TeammateBox";
import giangImg from "../../assets/img/team/Giang.png";
import giangPhanImg from "../../assets/img/team/giangPhan.jpg";
const clientList = [
  {
    img: client1,
  },
  {
    img: client2,
  },
  {
    img: client3,
  },
  {
    img: client4,
  },
  {
    img: client5,
  },
  {
    img: client6,
  },
  {
    img: client7,
    width: 150,
  },
  {
    img: client8,
    width: 150,
  },
  {
    img: client9,
    width: 150,
  },
  {
    img: client10,
    width: 120,
  },
  {
    img: client11,
  },
  {
    img: client12,
  },
  {
    img: client13,
    width: 150,
  },
  {
    img: client14,
  },
  {
    img: client15,
    width: 100,
  },
  {
    img: client16,
    width: 100,
  },
  {
    img: client17,
    width: 150,
  },
];

// var settings = {
//   dots: true,
//   infinite: true,
//   speed: 200,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: true,
// };

const teamList = [
  // {
  //   name: "Msc. Mr. Graham",
  //   role: "CEO - Chief Executive Officer",
  //   image: giangImg,
  //   link: "",
  // },
  // {
  //   name: "PHD. Quang Le",
  //   role: "Senior. AI Scientist, Product Lead",
  //   image: quangImg,
  //   link: "",
  // },
  // {
  //   name: "PHD. The Le",
  //   role: "Senior. AI Scientist",
  //   image: theImg,
  //   link: "",
  // },
  // {
  //   name: "PHD. Huy Bui",
  //   role: "Senior. AI Scientist",
  //   image: huyImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Giang Phan",
  //   role: "Commercial Director",
  //   image: giangPhanImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Hieu Hoang",
  //   role: "Senior. SE, Project Manager",
  //   image: hieuImg,
  //   link: "",
  // },
  // {
  //   name: "Giang Phan",
  //   role: "Sale Manager",
  //   image: giangPhanImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Thanh Phuong",
  //   role: "Data Scientist",
  //   image: thanhImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Nga Pham",
  //   role: "Data Scientist",
  //   image: ngaImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Loc Huynh",
  //   role: "AI Scientist",
  //   image: locImg,
  //   link: "",
  // },
  // {
  //   name: "Bsc. Hiep Tran",
  //   role: "AI Scientist",
  //   image: hiepImg,
  //   link: "",
  // },
];
export default function TeamPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const PrevArrow = ({ className, style, onClick }) => {
    return (
      <Box
        className={className}
        style={style}
        onClick={onClick}
        sx={{
          width: "40px",
          height: "40px",
          bgcolor: "#e4e0e0",
          borderRadius: "50%",
          display: "flex!important",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            bgcolor: "#e4e0e0!important",
          },
        }}
      >
        <MdKeyboardArrowLeft size={24} color="#5a5a5a" />
      </Box>
    );
  };

  // Component tùy chỉnh cho mũi tên sau
  const NextArrow = ({ className, style, onClick }) => {
    return (
      <Box
        className={className}
        style={style}
        onClick={onClick}
        sx={{
          width: "40px",
          height: "40px",
          bgcolor: "#e4e0e0",
          borderRadius: "50%",
          display: "flex!important",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            bgcolor: "#e4e0e0!important",
          },
        }}
      >
        <MdKeyboardArrowRight size={24} color="#5a5a5a" />
      </Box>
    );
  };

  const [setting, setSetting] = useState({
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  const targetRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        const targetPosition =
          targetRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollToPosition = targetPosition - 40; // Vị trí cao hơn 40px

        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth", // Cuộn mượt mà
        });
      }
    }, 500); // Thời gian chờ 500ms

    // Dọn dẹp timer khi component unmount
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {/* <Menu currentTab={1} /> */}
      {/* <!-- Header Area End -->
    <!-- Banner Area Start --> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                {t("home_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("about_page_title")}</Typography>
            </Box>
            <Typography className="text-banner">
              {t("about_page_title")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <!-- Banner Area End -->
    <!-- Experience Area Start --> */}
      <Grid container sx={{ overflow: "hidden" }}>
        <Grid item md={7} xs={12} data-aos="fade-right" data-aos-delay="500">
          <img src={womanTouch} />
        </Grid>
        <Grid
          data-aos="fade-left"
          data-aos-delay="500"
          item
          md={5}
          xs={12}
          sx={{
            "@media (max-width: 500px)": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            },
          }}
        >
          <div ref={targetRef} />
          <Typography className="tag-title">{t("about_company")}</Typography>
          <Typography
            sx={{
              color: "#d61f23",
              fontSize: "30px",
              marginBottom: "50px",
              marginTop: "50px",
              "@media (max-width: 500px)": {
                marginBottom: "20px",
                marginTop: "20px",
              },
            }}
          >
            {t("about_aivision")}
          </Typography>
          <Typography
            sx={{
              lineHeight: "30px",
              textAlign: "justify",
              paddingRight: "30px",
              "@media (max-width: 500px)": {
                padding: "0px 10px",
              },
            }}
          >
            {t("about_page_description")}
          </Typography>
        </Grid>
      </Grid>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mb={"15px"}
        sx={{
          background: "#f9f9f9",
          padding: "70px 0px 0px 0px",
          "@media (max-width: 500px)": {
            padding: "20px 0px 0px 0px",
          },
        }}
      >
        <ServiceOffter />
      </Box>
      {/**************** Team arear **************/}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          "@media (max-width: 500px)": {
            overflow: "hidden",
          },
        }}
      >
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "40px",
              margin: "30px",
              "@media (max-width: 500px)": {
                fontSize: "24px",
                margin: "20px",
              },
            }}
          >
            {t("team_grid")}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "1000px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {teamList.map((item, index) => {
            return (
              <div className="slide-item" key={index}>
                <TeammateBox
                  avatar={item.image}
                  name={item.name}
                  role={item.role}
                />
              </div>
            );
          })}
        </Box>
      </Box>

      {/**************** Client arear **************/}
      <Box
        py={"100px"}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          "@media (max-width: 500px)": {
            overflow: "hidden",
          },
        }}
      >
        <Box sx={{ maxWidth: "1000px", width: "100%" }}>
          <Box display={"flex"} alignItems={"start"} flexDirection={"column"}>
            <Typography
              sx={{
                lineHeight: "initial",
                textTransform: "uppercase",
                fontSize: "32px",
                fontWeight: "600",
                "@media (max-width: 500px)": {
                  fontSize: "24px",
                },
              }}
            >
              {t("client_grid")}
            </Typography>
          </Box>
          <Slider {...setting}>
            {clientList.map((item, index) => {
              return (
                <div className="slide-item" key={index}>
                  <div
                    style={{
                      margin: "5px 10px",
                      width: "150px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.img}
                      style={{
                        maxWidth: item.width ? item.width : "200px",
                        maxHeight: item.height ? item.height : "100px",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
